@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;900&display=swap);
.connectButton {
  border-radius: 10px;
  background-color: #000000 !important;
  opacity: 1 !important;
  min-width: 135px;
  height: 44px;
  border: none;
}

.optinButton {
  background: linear-gradient(92.97deg, #ffdc5e 0.13%, #fcc601 99.87%);
  border-radius: 10px;
  padding: 0.5rem 2rem;
  font-weight: 600;
  font-size: 1.4rem;
}

.closeButton {
  border: 1px solid #fcc601;
  border-radius: 10px;
  margin: 20px 20px 20px 0;
  width: 8rem;
}

.claimButton {
  border-color: #76dd73;
  color: white;
  margin: 20px 20px 20px 0;
}

.closeButton:hover {
  color: white;
}

@media only screen and (max-width: 768px) {
  .optinButton {
    font-size: 1rem;
    width: 10rem;
    padding: 0.4rem 1rem;
    margin: 25px 15px 10px 0;
  }
}

.landingPage {
  background-image: url(/static/media/background.36300b8f.svg);
  background-size: 100%;
  background-repeat: no-repeat;
  word-wrap: break-word;

  min-width: 100%;
  min-height: 100%;
  min-height: 100vh;
  background-color: #0b0e1a;
  overflow: auto;
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  margin-right: 3rem;
}

.middleContainer {
  display: flex;
  justify-content: space-around;
  /* align-items: center; */
  flex-wrap: wrap;

  margin: 10% 6rem;
}

.middleContainer__rewardsInfo {
  width: 18.125rem;
}

.middleContainer__rewardsInfo > h1 {
  font-weight: 600;
  font-size: 3.75rem;
  /* google ka kamal */
  background: -webkit-linear-gradient(#ffdc5e, #fc9701);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.accountInfo__address {
  display: flex;
  border-radius: 30px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  padding: 3px 8px;
  box-sizing: border-box;
  margin-bottom: 30px;
}

.accountInfo__address > p {
  margin: 10px 10px;
  font-weight: 600;
}

.accountInfo__rewardsBalance > div {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.whiteBold {
  color: white;
  font-weight: 600;
}

.middleContainer__claimToken {
  width: 38.4375rem;
  background: #252a3e;
  border-radius: 28px;

  padding: 2rem;
  max-height: 320px;
}

.middleContainer__claimToken > h3 {
  color: #ffffff;
  font-weight: 600;
  font-size: 24px;
}

.tokenCountContainer {
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  border-radius: 20px;
  padding: 1rem;
}
.tokenCountContainer > p {
  font-weight: 600;
  color: rgb(216, 216, 216);
  font-size: 1.2rem;
}

.walletChooseModal {
  background-color: #1f2333;
  border-radius: 20px;
  width: 42rem;
}

.walletChooseModal__body {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.walletName {
  border: 1px solid rgba(255, 255, 255, 0.2);
  width: 281px;
  height: 231px;
  border-radius: 20px;
  box-sizing: border-box;
  margin: 1.5rem;
}

.walletName:hover,
.walletLogo {
  cursor: pointer;
}

.walletName:hover {
  border: 1px solid white;
}

.walletName > div {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.walletLogo {
  margin-bottom: 10px;
  width: 170px;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .header {
    margin-right: 0rem;
  }

  .middleContainer {
    margin: auto;
    padding-top: 1rem;
  }

  .walletChooseModal {
    border-radius: 20px;
    width: 22rem;
  }
  .walletName {
    width: 150px;
    margin: 0 0 2rem;
    height: 150px;
  }
  .walletName > div > p {
    font-size: 13px;
  }
  .walletLogo {
    width: 100px;
  }
  .middleContainer__accountInfo {
    margin-top: 7rem;
  }

  .middleContainer__rewardsInfo > h1 {
    font-size: 2.8rem;
    margin-bottom: 0;
  }

  .middleContainer__claimToken {
    margin: 3rem 0;
    width: 20rem;
    padding: 15px 18px 15px;
  }
}

body,
html {
  margin: 0;
  padding: 0;
  font-family: "Poppins";
  min-height: 100%;
  min-height: 100vh;
  color: #ffffffb2;
}

