.connectButton {
  border-radius: 10px;
  background-color: #000000 !important;
  opacity: 1 !important;
  min-width: 135px;
  height: 44px;
  border: none;
}

.optinButton {
  background: linear-gradient(92.97deg, #ffdc5e 0.13%, #fcc601 99.87%);
  border-radius: 10px;
  padding: 0.5rem 2rem;
  font-weight: 600;
  font-size: 1.4rem;
}

.closeButton {
  border: 1px solid #fcc601;
  border-radius: 10px;
  margin: 20px 20px 20px 0;
  width: 8rem;
}

.claimButton {
  border-color: #76dd73;
  color: white;
  margin: 20px 20px 20px 0;
}

.closeButton:hover {
  color: white;
}

@media only screen and (max-width: 768px) {
  .optinButton {
    font-size: 1rem;
    width: 10rem;
    padding: 0.4rem 1rem;
    margin: 25px 15px 10px 0;
  }
}
