@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;900&display=swap");

body,
html {
  margin: 0;
  padding: 0;
  font-family: "Poppins";
  min-height: 100%;
  min-height: 100vh;
  color: #ffffffb2;
}
